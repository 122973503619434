<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <!-- <fb-button slot="right" icon="menu" size="small" type="primary">
      </fb-button> -->
    </fb-header>
    <!-- 默认插槽 -->
    <div class="search">
      <div class="search-header">
        <div class="search-header-left"
             @click="getType">
          <span>{{typeValue}}</span>
          <van-icon name="arrow" />
        </div>
        <div class="search-header-right"
             ref="search-header-right">
          <van-search v-model="value"
                      @input="changeValue" />
          <ul class="search-list"
              v-show="list.length !== 0"
              ref="search-list">
            <li v-for="(item,index) in list"
                class="van-hairline--bottom"
                @click="clickContent(item)"
                :key="index">{{item.c}}</li>
          </ul>
        </div>
      </div>
      <div class="search-content">
        <p class="van-hairline--bottom"
           v-for="(item,index) in content"
           :key="index">
          <span>{{index | types}}</span>
          <span>{{item}}</span>
        </p>
      </div>
    </div>
    <!-- 选择kuang -->
    <van-popup v-model="show"
               position="bottom"
               :overlay="true">
      <van-picker show-toolbar
                  :columns="columns"
                  @change="onChange"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
    <BMAP :mapname="mapname" />
  </fb-page>
</template>

<script>
import BMAP from './components/map'
import { caseBase } from '~api-config'

export default {
  name: 'contact-info',
  data () {
    return {
      title: '联系方式',
      show: false,
      picker: 0,
      value: '',
      typeValue: '法院',
      caseId: '',
      city: '',
      list: [],
      mapname: '',
      columns: ['法院', '检察院', '工商局', '公安局', '仲裁', '看守所', '监狱', '司法局'],
      content: {
        a: '', // 省份
        b: '', // 城市
        c: '', // 法院
        d: '', // 电话
        e: '', // 地址
        f: '' // 网址
      }
    }
  },
  components: {
    BMAP
  },
  filters: {
    types (index) {
      switch (index) {
        case 'a': return '省份：'
        case 'b': return '城市：'
        case 'c': return '院所：'
        case 'd': return '电话：'
        case 'e': return '地址：'
        case 'f': return '网址：'
      }
    }
  },
  methods: {
    confirm () {
      this.typeValue = this.columns[this.picker]
      const index = this.picker
      if (index === 0) {
        this.fayuan(this.caseId)
      } else {
        const data = {
          name: this.city,
          orgType: index
        }
        this.qitatype(data)
      }
      this.show = false
    },
    cancel () {
      this.show = false
    },
    onChange (picker, value, index) {
      this.picker = index
    },
    getType () {
      this.show = true
      this.list = []
    },
    // 法院查询
    fayuan (caseId) {
      this.$axios.get(caseBase + '/new-design/modals/queryCityByCaseId?caseId=' + caseId)
        .then(response => {
          const { court, city } = response.data.data
          this.city = city
          this.$axios.get(caseBase + '/lts/court/queryCourtByName', { params: { name: court } })
            .then(response => {
              const data = response.data.items[0]
              this.value = data.fullName
              this.mapname = data.fullName
              this.content = {
                a: data.province,
                b: data.city,
                c: data.fullName,
                d: data.phone,
                e: data.address,
                f: data.homepage
              }
            })
        })
    },
    // 其他类型
    qitatype (data) {
      this.$axios.get(caseBase + '/lts/basicInfo/findOrgByTypeAndName', { params: data })
        .then(response => {
          if (response.data.data.infos.length !== 0) {
            const data = response.data.data.infos[0]
            this.value = data.fullName
            this.mapname = data.fullName
            this.content = {
              a: data.province,
              b: data.city,
              c: data.fullName,
              d: data.phone,
              e: data.addr,
              f: data.url
            }
          } else {
            this.$toast('无默认值数据')
          }
        })
    },
    changeValue (value) {
      if (value) {
        if (this.picker === 0) {
          this.$axios.get(caseBase + '/lts/court/queryCourtByName', { params: { name: value } })
            .then(response => {
              const data = response.data.items
              this.list = data.map(data => {
                return {
                  a: data.province,
                  b: data.city,
                  c: data.fullName,
                  d: data.phone,
                  e: data.address,
                  f: data.homepage
                }
              })
            })
        } else {
          const data = {
            name: value,
            orgType: this.picker
          }
          this.$axios.get(caseBase + '/lts/basicInfo/findOrgByTypeAndName', { params: data })
            .then(response => {
              const data = response.data.data.infos
              this.list = data.map(data => {
                return {
                  a: data.province,
                  b: data.city,
                  c: data.fullName,
                  d: data.phone,
                  e: data.addr,
                  f: data.url
                }
              })
            })
        }
      } else {
        this.list = []
      }
    },
    clickContent (item) {
      this.value = item.c
      this.content = item
      this.list = []
      this.mapname = item.c
    }
  },
  created () {
    this.caseId = this.$route.params.caseId
    // 进来默认法院查询
    this.fayuan(this.caseId)
    // 查询框位置
  },
  mounted () {
    const top = this.$refs['search-header-right'].clientHeight
    this.$refs['search-list'].style.top = top + 3 + 'px'
  }
}
</script>

<style lang="stylus">
.search
  margin 10px 15px
  &::after
    content ''
    position fixed
    height 100%
    width 100%
    top 0
    left 0
    background #EFF7FB
    z-index -1
  & .search-header
    display flex
    justify-content space-between
  & .search-header-left
    display flex
    align-items center
    padding 10px
    color #169BD5
    border-radius 5px
    box-shadow 0px 0px 0px 1px rgba(64, 180, 233, 0.23)
    background #fff
    & >span
      margin-right 10px
      font-size 12px
    & .van-icon-arrow
      transform rotateZ(90deg)
  & .search-header-right
    // color #169BD5
    box-shadow 0px 0px 0px 1px rgba(64, 180, 233, 0.23)
    border-radius 5px
    display flex
    align-items center
    width calc(100% - 90px)
    background #fff
    position relative
    & .search-list
      position absolute
      background #fff
      width 100%
      border-radius 5px
      z-index 10
      font-size 14px
      top 0
      box-shadow 0px 0px 0px 1px rgba(64, 180, 233, 0.23)
      max-height 200px
      overflow hidden auto
      & li
        padding 5px
        color #666
    & .van-search
      background #fff !important
      padding 0
      width 100%
      & .van-field
        display flex
        align-items center
  & .search-content
    box-shadow 0px 0px 0px 1px rgba(64, 180, 233, 0.23)
    background #fff
    border-radius 5px
    margin-top 15px
    font-size 14px
    & p
      padding 10px
      color #666666
      display flex
      & span:first-child
        width 50px
</style>
