<template>
  <div id="bmap-container"></div>
</template>

<script>
export default {
  props: ['mapname'],
  data () {
    return {
      map: null
    }
  },
  watch: {
    mapname (name) {
      this.map.search(name)
    }
  },
  mounted () {
    // 百度地图API功能
    const loadJScript = () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '//api.map.baidu.com/api?v=2.0&ak=WXlmEPefuruCBj3eig1HOdNFGelW2jfP&callback=init'
      document.body.appendChild(script)
    }
    window.init = () => {
      const map = new window.BMap.Map('bmap-container') // 创建Map实例
      const point = new window.BMap.Point(116.404, 39.915) // 创建点坐标
      map.centerAndZoom(point, 15)
      map.enableScrollWheelZoom() // 启用滚轮放大缩小
      const local = new window.BMap.LocalSearch(map, {
        renderOptions: { map: map }
      })
      local.search(this.mapname)
      this.map = local
    }
    // 异步加载地图
    window.onload = loadJScript()
  }
}
</script>

<style scoped>
#bmap-container {
  position: fixed !important;
  height: calc(100% - 360px);
  width: 100%;
  bottom: 0;
}
</style>
